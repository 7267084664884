const env = {
  apiHost: "http://localhost:8085/",
  // apiHost: "https://tictactrump.io:8085/",
  TTTusdtprice: 0.0250,
  // testnet
  // tokenAddres: "0xA619B3a95BE94cC955F7A032009D53f2562bD3bE",
  // percentage: 1,
  // usdtAddres: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
  // BuyAddress: "0xb7f08b91a52dc7dd0afc2729553d754d0727a2b7",
  // VestingAddress: "0x6ea3b1f8e9321a8ba7047912dd0fb8acad979303",
  // rpc_url: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  // explorerUrl: "https://testnet.bscscan.com/",

  // mainnet
  tokenAddres: "0xf62410698f5612b5a32bf2b59fc5cbc617801a1d",
  percentage: 1,
  usdtAddres: "0x55d398326f99059fF775485246999027B3197955",

  BuyAddress: "0xA7D0192972818ca5f99AeC4b799c96fC0bE4d89e",
  VestingAddress: "0x84dc0d4c79793aDBaA59Cf26E48cC462ED17bE51",
  rpc_url: "https://base.llamarpc.com",
  explorerUrl: "https://basescan.org",
};


export default env;  
